@import '~theme/variables';

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-dialog-overlay;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.content {
  --max-height: 95svh;
  border-radius: $border-radius-04;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 64rem;
  max-height: var(--max-height);
  position: fixed;
  z-index: $z-index-dialog;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: $color-neutral-06;
  box-shadow: $box-shadow-01;

  &:focus {
    outline: none;
  }
}

.contentInner {
  position: relative;
  padding: $space-m;
  max-height: var(--max-height);
  overflow-y: auto;
}

.closeButton {
  flex-shrink: 0;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: $space-xs;
}

.title {
  margin-top: $space-xs;
}
